import React from 'react';
import s from './Divider.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type DividerTypes = {
  text: string;
};
const Divider = ({ text }: DividerTypes) => {
  const { hero } = usewpdataSelectors();

  return (
    <div className={s.divider}>
      <div></div>
      <p style={{ color: hero.styles.textColorPrimary }}>{text}</p>
      <div></div>
    </div>
  );
};

export default Divider;
