import { useCartActions } from 'lib/cartState/actions';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './Button.module.scss';

const Button = ({ text, className, attribute, onClickFn }: CTAProps) => {
  const { Theme, Settings, Layout } = usewpdataSelectors();

  const { toggleCartAction } = useCartActions();
  const isLink = Layout.hero.layout === 'lander';
  const cartDisabled = Settings.disableCart;
  const nextPageLink = Settings.link;

  if (isLink) {
    return (
      <a data-cy={attribute} href={Settings.link} data-theme={Theme?.button} className={`${className} cta ${s.cta}`}>
        {text}
      </a>
    );
  }

  return cartDisabled ? (
    <a data-cy={attribute} href={Settings.link} data-theme={Theme?.button} className={`${className} cta ${s.cta}`}>
      {text}
    </a>
  ) : (
    <button
      data-cy={attribute}
      onClick={onClickFn ? onClickFn : toggleCartAction}
      data-theme={Theme?.button}
      className={`${className} cta ${s.cta}`}
    >
      {text}
    </button>
  );
};

export default Button;
