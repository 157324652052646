import { useEffect, useState } from 'react';

const usePreloadImage = (src) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return loaded;
};

export default usePreloadImage;
